<template>
  <v-container>
    <v-card class="mx-auto col-md-9">
      <v-card-title class="d-flex justify-space-around pt-0">
        <h1 class="font-weight-medium text-center">{{ title }}</h1>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Tipo causal (*):</span
            >
          </v-col>

          <v-col cols="12" sm="8" md="8">
            <v-select
              :items="data_tipo_causales"
              outlined
              dense
              hide-details
              v-model="item.tpc_id"
            ></v-select>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Código (*):</span
            >
          </v-col>

          <v-col cols="12" sm="8" md="8">
            <v-text-field
              outlined
              dense
              hide-details
              type="number"
              v-model="item.csl_cdgo"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Detalle (*):</span
            >
          </v-col>

          <v-col cols="12" sm="8" md="8">
            <v-textarea
              filled
              auto-grow
              rows="4"
              row-height="15"
              v-model="item.csl_det"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Descripción detalle (*):</span
            >
          </v-col>
          <v-col cols="12" sm="8" md="8">
            <v-textarea
              filled
              auto-grow
              rows="4"
              row-height="15"
              v-model="item.csl_desp_det"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Observaciones:</span
            >
          </v-col>
          <v-col cols="12" sm="8" md="8">
            <v-textarea
              filled
              auto-grow
              rows="4"
              row-height="15"
              v-model="item.csl_obsv"
            ></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" sm="4" md="4">
            <span
              class="text-subtitle-1"
              :style="{ color: 'initial !important' }"
              >Servicios:</span
            >
          </v-col>
          <v-col cols="12" sm="8" md="8">
            <v-autocomplete
              v-model="item_serivios"
              :items="data_servicios"
              outlined
              dense
              chips
              small-chips
              multiple
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-row class="d-flex justify-space-around text-center">
          <v-col class="pb-2">
            <v-btn
              class="mx-3 white--text blue-bootstrap"
              @click="checForm(true)"
            >
              {{ buttontext }}
            </v-btn>
          </v-col>
          <v-col class="pb-2">
            <v-btn
              class="mx-3 white--text green-bootstrap"
              @click="checForm(false)"
            >
              Guardar
            </v-btn>
          </v-col>
          <v-col class="pb-2">
            <v-btn class="mx-3 red-bootstrap white--text" @click="cancel">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>

    <ModalForm
      :visible="loader.estado"
      @close="loader.estado = false"
      :message="loader.message"
    />
  </v-container>
</template>

<script>
import ModalForm from "../modal/ModalForm.vue";

export default {
  components: {
    ModalForm,
  },

  data: () => ({
    loader: {
      estado: false,
      message: "",
    },

    add: true,
    title: "",
    buttontext: "",
    close: false,
    item: {},

    item_serivios: [],
    data_tipo_causales: [],
    data_servicios: [],
  }),
  created() {
    let { add } = this.$route.query;
    this.add = String(add) == "true" ? true : false;

    if (this.add) {
      this.title = "Crear causal";
      this.buttontext = "Agregar causal";
    } else {
      this.title = "Actualizar causal";
      this.buttontext = "Actualizar causal";
    }

    this.getServicios();
  },

  methods: {
    getServicios() {
      this.loader.message =
        "Por favor espere mientras se consultan los datos..";

      this.loader.estado = true;
      let uri = "/servicios/" + String(this.$store.state.company);

      this.axios
        .get(uri)
        .then((response) => {
          response.data.forEach((element) => {
            this.data_servicios.push({
              text: `${element.srv_cdgo} - ${element.srv_nombre}`,
              value: element._id,
            });
          });

          this.getTipoCausales();
        })
        .catch((error) => {
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },

    getTipoCausales() {
      let uri = "/tipocausales/" + String(this.$store.state.company);

      this.axios
        .get(uri)
        .then((response) => {
          response.data.forEach((element) => {
            this.data_tipo_causales.push({
              text: `${element.tpc_cdgo} - ${element.tpc_nombre}`,
              value: element._id,
            });
          });

          if (!this.add) this.getItem(this.$route.query.id);
          this.loader.estado = false;
        })
        .catch((error) => {
          this.loader.estado = false;
          this.$emit("msj", { text: error.message });
        });
    },
    getItem(id = "") {
      let uri = "/causales/edit/" + id;

      this.axios
        .get(uri)
        .then((response) => {
          response.data.servicios.forEach((e) => {
            this.item_serivios.push(e.srv_id);
          });
          this.item = response.data;
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    async checForm(close) {
      let data = this.item;
      let servicios = this.item_serivios || [];

      if (!data.csl_cdgo) {
        this.$emit("msj", { text: "Código es obligatorio" });
      } else if (!data.csl_det) {
        this.$emit("msj", { text: "Detalle es obligatorio" });
      } else if (!data.csl_desp_det) {
        this.$emit("msj", { text: "Descripcion del detalle es obligatorio" });
      } else if (!servicios.length) {
        this.$emit("msj", { text: "Selecione un servicio" });
      } else {
        data.servicios = [];

        for await (const item of servicios) {
          data.servicios.push({ srv_id: item });
        }

        this.close = close;

        if (this.add) this.createCausal();
        else this.updateCausal();
      }
    },

    createCausal() {
      this.item.cmp_id = String(this.$store.state.company);
      this.axios
        .post("/causales/add", this.item)
        .then(() => {
          if (this.close) this.cancel();
          this.$emit("msj", { text: "Registro guardado", estado: "success" });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    updateCausal() {
      let uri = "/causales/update/" + this.item._id;

      this.axios
        .put(uri, this.item)
        .then(() => {
          if (this.close) this.cancel();
          this.$emit("msj", {
            text: "Registro actualizado",
            estado: "success",
          });
        })
        .catch((error) => {
          this.$emit("msj", { text: error.message });
        });
    },

    cancel() {
      this.$router.push({ name: "DisplayCausal" });
    },
  },
};
</script>